@font-face {
  font-family: "Sura", serif;
  src: local("Sura"), url(./Sura-Bold.ttf) format("truetype");
}

html,
body,
#root {
  height: 100%;
  background: var(--theme-background);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes wave {
  20%,
  30% {
    margin-top: -25%;
  }
  25% {
    margin-top: -30%;
  }
  70%,
  80% {
    margin-top: 25%;
  }
  75% {
    margin-top: 30%;
  }
}
